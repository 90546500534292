<template>
	<div class="dropdown">
		<button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			{{ selectedYear }}
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
			<a class="dropdown-item" href="#" v-for="year in years" :key="year" @click="selectYear(year)">{{ year }}</a>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 40
    },
    value: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      years: []
    };
  },
  mounted() {
    this.populateYears();
  },
  computed: {
    selectedYear: {
      get() {
        return this.value;
      },
      set(year) {
        this.$emit('input', year);
      }
    }
  },
  methods: {
    populateYears() {
      const currentYear = new Date().getFullYear();
      const nextYears = currentYear + this.max;

      for (let year = currentYear; year <= nextYears; year++) {
        this.years.push(year);
      }
    },
    selectYear(year) {
      this.selectedYear = year; // Update the selected year
      console.log("Selected year:", year);
    }
  }
};
</script>
